.switch-pay {
    position:absolute;
   
    width: 170px;
    height: 50px;
   }
   
   .switch-pay input {
    display: none;
   }
   
   .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #3C3C3C;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
   }
   
   .slider:before {
    position: absolute;
    content: "";
    height: 44px;
    width: 44px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
   }
   
   .pay-input:checked + .slider {
    background-color: #0E6EB8;
   }
   
   .pay-input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
   }
   
   .pay-input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(117px);
   }
   
   /*------ ADDED CSS ---------*/
   .slider:after {
    content: 'Havala İle Öde';
    color: white;
    display: block;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    font-size: 10px;
   }
   
   .pay-input:checked + .slider:after {
    content: 'Kart İle Öde';
   }
   
   /*--------- END --------*/