@media only screen and (max-width: 990px) {
    .colorsforheader{
        color:white;
        
       
    }
    .colorsforheader:hover{
        color:white;
        
       
    }
}
@media only screen and (min-width: 990px) {
    .colorsforheader{
    }
    .colorsforheader:hover{
    }
}